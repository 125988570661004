import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { IsMobileContext, LanguageContext } from '../common/Context'
import { AnimationService } from '../common/AnimationService'

//components
import Layout from '../components/Layout'
import HeroSection from '../components/HeroSection'
import FloatingButton from '../components/FloatingButton'
import Footer from '../components/Footer'
import ImageVideoSection from '../components/ImageVideoSection'
import ClientsSection from '../components/ClientsSection'
import Navbar from '../components/Navbar'
import CaseStudySection from '../components/CaseStudySection'

//models, hooks
import { useSeoData } from '../components/SeoComponent/model'
import { useLinksGridData, LinksGrid } from '../components/LinksGrid/model'
import { useClientsSectionData, ClientsSection as ClientsSectionClass } from '../components/ClientsSection/model'
import { useImageVideoSectionData, ImageVideoSection as ImageVideoSectionClass } from '../components/ImageVideoSection/model'
import { useNavbarData, Navbar as NavbarClass } from '../components/Navbar/model'
import { useHeroSectionData, HeroSection as HeroSectionClass } from '../components/HeroSection/model'
import { useCaseStudySectionData, CaseStudySection as CaseStudySectionClass } from '../components/CaseStudySection/model'

//other
import withSSR from '../hoc/withSSR'
import './work.scss'

const WorkPage: React.FC<PageProps> = ({ location: { pathname } }) => {
  AnimationService.init()
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)

  /*  For data collecting hooks below (e.g. useLinksGridData),
  You need to know page codename from kentico and optionally section codename.
  Data service is searching query for those elements and collects the right data to components  */
  const pageCodename = 'work_page'
  const seoData = useSeoData(language, pageCodename, pathname)
  const pageData = {
    linksGridData: useLinksGridData(language, pageCodename),
    navbarData: useNavbarData(language, pageCodename, pathname),
    clientsSectionData: useClientsSectionData(language, pageCodename),
    imageVideoSectionData: useImageVideoSectionData(language, pageCodename),
    heroSectionData: useHeroSectionData(language, pageCodename),
    caseStudySectionData: useCaseStudySectionData(language),
  }

  return (
    <Layout seoData={seoData} language={language} setLanguage={setLanguage}>
      <WorkPageTemplateWithSSR pageData={pageData} />
    </Layout>
  )
}

interface WorkPageProps {
  isMobile: boolean
  pageData: {
    linksGridData: LinksGrid
    navbarData: NavbarClass
    clientsSectionData: ClientsSectionClass
    imageVideoSectionData: ImageVideoSectionClass
    heroSectionData: HeroSectionClass
    caseStudySectionData: CaseStudySectionClass
  }
}

const WorkPageTemplate: React.FC<WorkPageProps> = ({
  isMobile,
  pageData: {
    linksGridData,
    navbarData,
    clientsSectionData,
    imageVideoSectionData,
    heroSectionData,
    caseStudySectionData,
  }
}) => {

  const headerRef = React.useRef<HTMLDivElement>(null)
  const leadRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (headerRef.current && leadRef.current) {
      if (!AnimationService.isInView(headerRef.current)) {
        const elementsToAnimate = [headerRef.current, leadRef.current]
        AnimationService.slideSequenceUp(elementsToAnimate)
      }
    }
  }, [])

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} />
      </header>
      <main className="Lead">
        <HeroSection {...heroSectionData} headingColumns={{desktop: 6, mobile: 12}} descriptionColumns={{desktop: 6, mobile: 12}} />
        <CaseStudySection {...caseStudySectionData} loadMore casesPerPage={16} />
        <ClientsSection {...clientsSectionData} />
        <ImageVideoSection {...imageVideoSectionData} />
      </main>
      <footer>
        <Footer {...linksGridData} />
      </footer>
      <FloatingButton />
    </IsMobileContext.Provider>
  )
}
const WorkPageTemplateWithSSR = withSSR<WorkPageProps>(WorkPageTemplate)

export default WorkPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","work"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
